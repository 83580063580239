@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Roboto&display=swap');



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.foto-size {
  height: 392px;
  width: 315px;
}

@media only screen and (max-width: 600px) {
  .foto-size {
    height: 196px;
    width: 157px;
  }
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  display: inline-grid;
  grid-gap: 0rem;
  grid-template-columns: repeat(5,auto);
  grid-template-rows: repeat(6, auto);
}

.body{
  background-color: #f8f9fa;
  text-align: center;
}

.semana{
  background-color: #dddddd;
  height: 25px;
  width: 150px;
  border: 1px solid black;
}

.fecha{
  height: 25px; width: 150px; background-color: lightblue; border: 1px solid black; text-align: end;
}

.horario{
  height: 175px; width: 150px; background-color: grey;
}

.hora{
  height: 50px;
  width: 150px;
  border: 1px solid black;
  background-color: #dddddd;
}

.hora-vacio {
  height: 25px;
  width: 150px;
  border: 1px solid black;
  background-color: #dddddd;
}

.hora-lg:hover {
  background-color: yellow;
  cursor: pointer;
  color: black;
}

.hora-lg-occupied:hover {
  background-color: yellow;
  cursor: pointer;
  color: black;
}

.hora-lg{
  height: 50px;
  width: 150px;
  border: 1px solid black;
  background-color: green;
  color: white;
}

.hora-lg-occupied{
  height: 50px;
  width: 150px;
  border: 1px solid black;
  background-color: #bbbbbb;
}

.hora-lg-other{
  height: 50px;
  width: 150px;
  border: 1px solid black;
  background-color: red;
  color: white;
}

.hora-lg-other:hover{
  background-color: yellow;
  cursor: pointer;
  color: black;
}

.hora-lg-out{
  height: 50px;
  width: 150px;
  border: 1px solid black;
  background-color: white;
}


.foto-padding {
  padding: 20px;
}

.div-foto {
  text-align: center;
}

.login-form {
  max-width: 400px;
}

.div-precios {
  margin: 20px auto;
}

th, td {
  font-size: 25px;
}

.contactese {
  text-align: center;
}

.consulta {
  background-color: green;
  color: white;
  border: black;
  padding: 10px;
}

.volver {
  margin-top: 20px;
  background-color: green;
  color: white;
  border: 1px solid white;
  height: 30px;
  width: 150px;
}

.volver:hover {
  background-color: white;
  color: green;
  border: 1px solid white;
}

.cancelar {
  margin-top: 20px;
  background-color: red;
  color: white;
  border: 1px solid white;
  height: 30px;
  width: 150px;
}

.cancelar:hover {
  margin-top: 20px;
  background-color: white;
  color: red;
  border: 1px solid white;
  height: 30px;
  width: 150px;
}

.block {
  display: block;
  font-size: 30px;
}

input[type=radio] {
  border: 0px;
  width: 100%;
  height: 1em;
}

form div {
  font-size: 25px;
}

.agendar {
  background-color: green;
  border: 1px solid black;
  color: white;
  height: 50px;
  width: 250px;
  font-size: 20px;
}

.agendar:hover {
  background-color: white;
  color: green;
  border: 1px solid black;
}

.volverIndex {
  margin-top: 20px;
  background-color: green;
  border: 1px solid black;
  color: white;
  height: 40px;
  width: 200px;
  font-size: 20px;
}

.volverIndex:hover {
  background-color: white;
  color: green;
  border: 1px solid black;
}

.squareSize {
  height: 50px;
  width: 150px;
  font-size: 16px;
}

.fechaSize {
  height: 25px;
  width: 150px;
}

.horaSize {
  width: 150px;
}

.mesNavigation {
  background-color: green;
  border: 1px solid black;
  color: white;
  height: 40px;
  width: 200px;
  font-size: 20px;
}

.mesNavigation:hover {
  background-color: white;
  color: green;
  border: 1px solid black;
}

@media only screen and (max-width: 600px) {
  .squareSize {
    height: 50px;
    width: 75px;
    font-size: 11px;
  }
  
  .fechaSize {
    height: 25px;
    width: 75px;
  }
  
  .horaSize {
    width: 75px;
  }
}